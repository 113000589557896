
import React from 'react'
import MainLayout from './Main'

interface IProps {
  children: any
}

const CurriculumLayout = ({ children }: IProps) => {
  return (
    <MainLayout>
      { children }
    </MainLayout>
  )
}

export default CurriculumLayout
