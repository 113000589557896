import { graphql } from "gatsby"
import React from "react"
import Nav from 'components/pages/Curriculum/components/Nav'
import Header from 'components/pages/Curriculum/components/Header'
import Content from 'components/pages/Curriculum/components/Content'
import SEO from 'components/SEO'
import Layout from "../layouts/Curriculum"

interface IProps {
  path: string,
  data: {
    site: {
      siteMetadata: {
        siteUrl: string
      }
    }
    strapiCurriculumCategories: Strapi.CurriculumCategory
  }
}

const CurriculumCategoryTemplate = (props: IProps) => {
  if (typeof window === 'undefined') return null
  const category = props.data.strapiCurriculumCategories
  const pages = category.curriculum_pages

  const primary = category.Header?.bgGradient?.from

  const SEOProps = {
    title: category.SEO?.title,
    titleTemplate: "%s | Night Zookeeper",
    image: category.SEO?.image?.url,
    url: category.SEO?.url,
    canonical: category.SEO?.canonical || `${props.data.site.siteMetadata.siteUrl}${props.path}`,
    description: category.SEO?.description,
  }

  return (
    <Layout>
      <Header {...category.Header} />
      <Nav items={[
        { slug: 'overview', title: 'Overview', to: `/curriculum/${category.slug}`},
        ...pages.map(p => ({
        slug: p.slug,
        title: p.title,
        to: `/curriculum/${category.slug}/${p.slug}`
      }))]} selected='overview' />
      <Content content={category.content} primary={primary} />
      <SEO {...SEOProps} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    strapiCurriculumCategories(slug: { eq: $slug }) {
      slug
      title
      curriculum_pages(sort: { fields: slug, order: ASC }) {
        id
        title
        slug
      }
      Header {
        title
        subTitle
        button {
          id
          value
          theme
          targetUrl
        }
        image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 660, placeholder: BLURRED)
            }
          }
        }
        bgGradient {
          from
          to
          angleDegree
        }
      }
      content
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default CurriculumCategoryTemplate
