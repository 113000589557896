import React from 'react'
import RichTextRenderer from 'components/UI/RichTextRenderer'

interface IProps {
  content: string,
  primary?: string
}

const Content = (props: IProps) => {
  return <>
    { props.content && <RichTextRenderer primary={props.primary || '#962E63'}>
      {props.content}
    </RichTextRenderer> }
  </>
}

Content.defaultProps = {
  primary: '#962E63'
}
export default Content
