import { Link } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  background-color: #ebebeb;

  padding: 10px 0;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`

const Item = styled.div`
  position: relative;
  padding: 5px 20px;
  ::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    ${({ selected }: { selected: boolean }) =>
      selected &&
      css`
        border-bottom: 1px solid rgba(0,0,0,0.1);
      `}
  }
`

interface IProps {
  items: {
    title: string,
    slug: string,
    to: string,
  }[],
  selected: string
}

const Header = (props: IProps) => {
  return <Wrapper>
    <Items>
      {
        props.items.map(item =>
          <Link key={item.slug} to={item.to}>
            <Item className='nav-item' selected={item.slug === props.selected}>
              { item.title }
            </Item>
          </Link>)
      }
    </Items>
  </Wrapper>
}

export default Header
